import { Component, OnInit} from '@angular/core';
import { Platform, LoadingController, NavController, ModalController, AlertController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
@Component({
  selector: 'app-help',
  templateUrl: './help.page.html',
  styleUrls: ['./help.page.scss'],
})

export class HelpPage implements OnInit {
  device_type: any;
  isWebVersion: any = false;
  constructor(private iab: InAppBrowser,private platform: Platform ) { 
    try {
      this.initializeApp();
    } catch (e) {

    }
  }

  ngOnInit() {
    try {
      this.initializeApp();
    } catch (e) {

    }

  }
  async initializeApp() {
    try {
      this.platform.ready().then(() => {
        if (this.platform.is("ios")) {
          this.isWebVersion = false;
          this.device_type = "IOS";
        } else if (this.platform.is("android")) {
          this.isWebVersion = false;
          this.device_type = "ANDROID";
        } else {
          this.isWebVersion = true;
          this.device_type = "WEB";
        }
      });
    } catch (error) {
    }
  }
  viewCircular(){
    this.openWebpage('http://shapp.nrl.co.in/help/circular20190731.pdf');
}
  viewDocument(){
      this.openWebpage('http://shapp.nrl.co.in/help/manual.html');
  }
  openWebpage(url: string) {
    const options: InAppBrowserOptions = {
      toolbar: 'yes',
      hideurlbar: 'no',
      fullscreen: "no",
      location: 'yes',
      zoom: 'no',
    }
    const browser = this.iab.create(url, '_blank', options);
    browser.show();
  }
}
