import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';

@Injectable({
  providedIn: 'root'
})
export class DbTaskService {

  private db: SQLiteObject;
  private isOpen: boolean;
  constructor(private sqlite: SQLite) { 
   /* if(!this.isOpen){
      try{
          this.sqlite.create({
          name: 'bspl_nrl_ghb.db',
          location: 'default'
          }).then((db: SQLiteObject) => {
            this.db = db;
              db.executeSql('CREATE TABLE IF NOT EXISTS Notifications(notificationid INTEGER PRIMARY KEY AUTOINCREMENT, date TEXT, subject TEXT, description TEXT, isread INT)', [])
              .then(() => //  console.log('Executed SQL'))
              .catch(e => //  console.log(e)); 
              this.isOpen = true;   

           });
      } catch (error) {
          //  console.log(error);
      }

    }*/
  }

  creatNotififation(date :string, subject:string, description :string, isread :number){

    
    return new Promise ((resolve , reject) => {
     /* let sql = "INSERT INTO Notifications (subject, description, date,isread) VALUES (?, ?, ?, ?)";
      this.db.executeSql(sql, [subject, description, date,isread]).then((data) => {
        //  console.log('Data Inserted')
        resolve(data);
      }, (error) => {
        reject(error);
      }); */
      //********************************************** */
      
      try{
        this.sqlite.create({
        name: 'bspl_nrl_ghb.db',
        location: 'default'
        }).then((dbs: SQLiteObject) => {
          this.db = dbs;
          let sql = "INSERT INTO Notifications (subject, description, date,isread) VALUES (?, ?, ?, ?)";
          this.db.executeSql(sql, [subject, description, date,isread]).then((data) => {
            //  console.log('Data Inserted')
            resolve(data);
          }, (error) => {
            reject(error);
          });

        });
      } catch (error) {
        //  console.log(error); reject(error);
      }
      
      //********************************************* */
    });
  }
  
  listNotification(){
    this.dbCheck();
    return new Promise ((resolve , reject) => {

      
      try{
        this.sqlite.create({
        name: 'bspl_nrl_ghb.db',
        location: 'default'
        }).then((dbs: SQLiteObject) => {
          this.db = dbs;
          this.db.executeSql("SELECT * FROM Notifications order by notificationid desc", []).then((data) => {
            let arrayNotification = [];
          // subject, description, date,isread
            if(data.rows.length > 0){
              for (var i=0; i < data.rows.length; i++){
                arrayNotification.push({
                  notificationid: data.rows.item(i).notificationid,
                  subject: data.rows.item(i).subject,
                  description: data.rows.item(i).description,
                  date: data.rows.item(i).date,
                  isread: data.rows.item(i).isread,
                });
              }
            }
            resolve(arrayNotification); 
          }, (error) => {
              reject(error);
          })

        });
      } catch (error) {
        //  console.log(error); reject(error);
      }
      /*
      this.db.executeSql("SELECT * FROM Notifications order by notificationid desc", []).then((data) => {
        let arrayNotification = [];
      // subject, description, date,isread
        if(data.rows.length > 0){
          for (var i=0; i < data.rows.length; i++){
            arrayNotification.push({
              notificationid: data.rows.item(i).notificationid,
              subject: data.rows.item(i).subject,
              description: data.rows.item(i).description,
              date: data.rows.item(i).date,
              isread: data.rows.item(i).isread,
            });
          }
        }
        resolve(arrayNotification);
      }, (error) => {
        reject(error);
      })*/
    });
  }
  getUnreadNotification(){
    this.dbCheck();
    return new Promise ((resolve , reject) => {
      /*
      this.db.executeSql("SELECT count(*) as unread FROM Notifications where isread=0", []).then((data) => {
       
        resolve(data);
      }, (error) => {
        reject(error);
      })
*/

      try{
        this.sqlite.create({
        name: 'bspl_nrl_ghb.db',
        location: 'default'
        }).then((dbs: SQLiteObject) => {
          this.db = dbs;
          this.db.executeSql("SELECT count(*) as unread FROM Notifications where isread=0", []).then((data) => {
        
            resolve(data); 
          }, (error) => {
              reject(error);
          })

        });
      } catch (error) {
        //  console.log(error); reject(error);
      }



    });

  }

  dbCheck(){
   
      try{
          this.sqlite.create({
          name: 'bspl_nrl_ghb.db',
          location: 'default'
          }).then((dbs: SQLiteObject) => {
            this.db = dbs;
              dbs.executeSql("CREATE TABLE IF NOT EXISTS Notifications(notificationid INTEGER PRIMARY KEY AUTOINCREMENT, date TEXT, subject TEXT, description TEXT, isread INT)", [])
              .then(() => {}
              //  console.log('Executed SQL')
              ).catch(e =>{}
                 //  console.log(e)
                 ); 
              this.isOpen = true;   

           });
      } catch (error) {
          //  console.log(error);
      }

    
  }
}
