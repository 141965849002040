import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-app-update',
  templateUrl: './app-update.page.html',
  styleUrls: ['./app-update.page.scss'],
})
export class AppUpdatePage implements OnInit {
  pageTitle="";
  type=-9;
  message="";
  constructor(private navParams:NavParams,) {
    this.pageTitle = this.navParams.get('heading');
    this.type = this.navParams.get('type');
    this.message = this.navParams.get('message');
    
   }

  ngOnInit() {
  }
  doUpdate(){
    window.open("https://play.google.com/store/apps/details?id=com.bspl.nrlgh", "_system", "location=yes");
  }
}
