import { Component, OnInit } from '@angular/core';
import { NavController,ModalController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
import { ActivatedRoute, Router ,ParamMap } from '@angular/router';
@Component({
  selector: 'app-guest-house-selector',
  templateUrl: './guest-house-selector.page.html',
  styleUrls: ['./guest-house-selector.page.scss'],
})
export class GuestHouseSelectorPage implements OnInit {
  GuestHouseList:any;
  constructor(private modalCtrl:ModalController,private navParams:NavParams, private route: ActivatedRoute,) { }

  ngOnInit() {

    const something: string = this.navParams.get('test');
    //  //  console.log(""+something);
    this.GuestHouseList = JSON.parse(this.navParams.get('guesthouses'));
    //  //  console.log(""+JSON.stringify(this.GuestHouseList));
   
  }
  closeModal()
  {
    let guest_house_data = {status: false};
    this.modalCtrl.dismiss(guest_house_data);
  }

  onSelectGuestHouse(name,code,location){
    let guest_house_data = {status: true,name: name, code: code,location:location};
    this.modalCtrl.dismiss(guest_house_data);
  }


}
