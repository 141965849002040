import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { LoadingController, AlertController, NavController, ModalController } from '@ionic/angular';

import { NavParams } from '@ionic/angular';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-approve-booking-model',
  templateUrl: './approve-booking-model.page.html',
  styleUrls: ['./approve-booking-model.page.scss'],
})
export class ApproveBookingModelPage implements OnInit {

  myForm: FormGroup;
  myRequestForm: FormGroup;
  isFoodChargable = false;
  isRoomChargable = false;
  isFoodChargableDisabled = false;
  isRoomChargableDisabled = false;


  bookingno: any = "";
  requestType=0;
  booking;
  constructor(private navParams: NavParams, public router: Router, public modalCtrl: ModalController, public alertController: AlertController, public navCtrl: NavController, public authService: AuthService,
    private route: ActivatedRoute, private formBuilder: FormBuilder, public loadingController: LoadingController) {
    this.myForm = this.formBuilder.group({
      comments: ['', Validators.compose([Validators.maxLength(40), Validators.minLength(3), Validators.required])],
      food_chargable: [],
      room_chargable: [],


    });

  }
  closeModal() {
    let booking_data = { status: false };
    this.modalCtrl.dismiss(booking_data);
  }
  ngOnInit() {
    this.booking = JSON.parse(this.navParams.get('booking'));

    this.bookingno = this.navParams.get('bookingno');
    this.requestType = this.navParams.get('requestType');

    if (this.booking.isfoodchargeable == "YES") {
      this.isFoodChargable = true;
    } else {
      this.isFoodChargable = false;
    }

    if (this.booking.isroomchargeable == "YES") {
      this.isRoomChargable = true;
    } else {
      this.isRoomChargable = false;
    }
    if (this.booking.bookingtype == "Personal") {
      this.isRoomChargableDisabled = true;
      this.isFoodChargableDisabled = true;
    }
    if (this.requestType == 2) {
      this.isRoomChargableDisabled = true;
      this.isFoodChargableDisabled = true;
    }
    
  }
  roomChargableClick(e) {


    let formObj = this.myForm.getRawValue();
    if (formObj["room_chargable"]) {
      this.isRoomChargable = true;
     // console.log('room_chargable :: YES');
    } else {
      this.isRoomChargable = false;
     // console.log('room_chargable :: NO');
    }
  }


  foodChargableClick(e) {

    let formObj = this.myForm.getRawValue();
    if (formObj["food_chargable"]) {
     // console.log('food_chargable :: YES');
      this.isFoodChargable = true;

    } else {
      this.isFoodChargable = false;
      //console.log('food_chargable :: NO');
    }
  }
  doSubmit() {

    let formObj = this.myForm.getRawValue();
    let food_chargable = "YES";
    let room_chargable = "YES";
    let remarks = formObj["comments"];

    room_chargable = this.booking.isroomchargeable;
    food_chargable =this.booking.isfoodchargeable;

    if ( this.isRoomChargable) {
      room_chargable = 'YES';
    } else {
      room_chargable = 'NO';
    }
    if ( this.isFoodChargable) {
      food_chargable = 'YES';
    } else {
      food_chargable = 'NO';
    }


    this.submitApproveRejectRequest(this.bookingno,food_chargable,room_chargable, remarks, "APPROVED")
  }
 doSubmit1() {

    let formObj = this.myForm.getRawValue();
    let food_chargable = "YES";
    let room_chargable = "YES";
    let remarks = formObj["comments"];
    room_chargable = this.booking.isroomchargeable;
    food_chargable =this.booking.isfoodchargeable;
 

    this.submitApproveRejectRequest(this.bookingno, food_chargable,room_chargable,remarks, "REJECTED")
  }

  async submitApproveRejectRequest(bookingno,food_chargable,room_chargable, reason, approvalstatus) {
    const loading = await this.loadingController.create({
      animated: true,
      spinner: "bubbles",
      message: 'Updating  ...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    await loading.present();
    let formObj = {
      bookingno: bookingno,
      approvalremarks: reason,
      approvalstatus: approvalstatus,
      isfoodchargeable: food_chargable,
      isroomchargeable: room_chargable
    };

    let serializedForm = JSON.stringify(formObj);
    //  console.log( JSON.stringify(formObj));

    await this.authService.postDataWithAuth('/api/approverequest', serializedForm).subscribe(res => {
      try {
        //  console.log( JSON.stringify(res));
        if (res['status'] == 1) {
          this.presentAlert("Success", res['message']);

          let booking_data = { status: true };
          this.modalCtrl.dismiss(booking_data);
          //  this.loadPendingApprovals();
        } else {
          this.presentAlert("Alert", res['message']);
        }



      } catch (Exce) {
        this.presentAlert("Alert", "Unable to update booking status.");
      } finally {
        loading.dismiss();
      }
    }, (err) => {
      try {

        //  console.log( JSON.stringify(err));

      } catch (exception) {
        //  console.log(exception);
      } finally {
        loading.dismiss();
      }
    });
  }
  async presentAlert(header, message) {

    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: ['OK']
    });
    return await alert.present();
  }
}
