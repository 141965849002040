import { Component, OnInit,ChangeDetectorRef } from '@angular/core';

import { NavController, ModalController } from '@ionic/angular';
@Component({
  selector: 'app-vacancy-date-range-selector',
  templateUrl: './vacancy-date-range-selector.page.html',
  styleUrls: ['./vacancy-date-range-selector.page.scss'],
})
export class VacancyDateRangeSelectorPage implements OnInit {

  isCheckInSelected: boolean = true;
  CheckInDateText: any;
  CheckOutDateText: any;
  CheckInDate;
  CheckOutDate;

  CheckInMinDate;
  CheckInMaxDate;

  CheckOutMinDate;
  CheckOutMaxDate;
  isValidDate = true;

  toDateForTo  = new Date();
  toDateForFrom  = new Date();

  constructor(private cdr: ChangeDetectorRef,private modalCtrl: ModalController) {
    let toDay = new Date();
    this.CheckInMinDate = new Date();
    this.CheckInMaxDate = new Date();
    this.CheckInMaxDate.setTime(toDay.getTime() - (24*60*60*1000));
   // [toDate]="toDateForFrom" 
  

    //this.toDateForFrom = new Date(toDay.setDate(toDay.getDate() - 1));

  //  this.toDateForFrom.setTime(toDay.getTime() - (24*60*60*1000));
    try {
      this.cdr.detectChanges();
    } catch (error) {
      
    }
    this.CheckInDate = new Date();
    this.CheckOutDate = new Date();

    this.toDateForFrom = new Date();
    
    this.CheckOutDate.setTime( this.CheckInDate.getTime() +(24*60*60*1000)); 
    this.CheckInDateText = this.formatDate(this.CheckInDate);
    this.CheckOutDateText = this.formatDate(this.CheckOutDate);

    this.CheckOutMinDate = new Date();
    this.CheckOutMinDate.setDate(this.CheckInDate.getDate());
  }


  ngOnInit() {
  }
  closeModal() {
    let guest_house_data = { status: false };
    this.modalCtrl.dismiss(guest_house_data);
  }
  selectCheckInDate() {
    this.isCheckInSelected = true;
  }

  selectCheckOutDate() {
    this.isCheckInSelected = false;
  }
  CheckInDateSelected(date) {



    this.CheckInDate = new Date(date);
    this.CheckInDateText = this.formatDate(date);
    this.CheckOutMinDate = new Date(date);
    this.CheckOutMaxDate = new Date(date);
    this.CheckOutDate = new Date(date);

    this.CheckOutMaxDate.setDate(new Date());
    this.CheckOutDate.setDate(this.CheckInDate.getDate() + 1);
    this.CheckOutMinDate.setDate(this.CheckInDate.getDate() + 1);
    /*
    this.CheckOutMaxDate.setTime( this.CheckInDate.getTime() +(30*24*60*60*1000));
    this.CheckOutDate.setTime( this.CheckInDate.getTime() +(24*60*60*1000)); 
    this.CheckOutMinDate.setTime( this.CheckInDate.getTime() +(24*60*60*1000)); */

    this.CheckOutDateText = this.formatDate(this.CheckOutDate);
    let TotalDays = this.getDays(this.CheckInDate, this.CheckOutDate);
    if (TotalDays > 0) {
      this.isValidDate = true;
    } else {
      this.isValidDate = false;
    }
    this.selectCheckOutDate();
  }
  CheckOutDateSelected(date) {
    this.CheckOutDate = new Date(date);
    this.CheckOutDateText = this.formatDate(date);
    let TotalDays = this.getDays(this.CheckInDate, this.CheckOutDate);
    if (TotalDays > 0) {
      this.isValidDate = true;
    } else {
      this.isValidDate = false;
    }

  }

  formatDate(date) {
    let d = new Date(date),
      day = '' + d.getDate(),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }


  onSubmit() {
    let TotalDays = this.getDays(this.CheckInDate, this.CheckOutDate);
    //  //  //  console.log("TotalDays "+TotalDays);
    if (TotalDays > 0) {
      let check_in_data = { status: true, from_date: this.CheckInDate, to_date: this.CheckOutDate };
      this.modalCtrl.dismiss(check_in_data);
    } else {
      this.isValidDate = false;
      //  //  //  console.log("In Valid Date :: TotalDays : "+TotalDays);
    }



  }

  getDays(fromdate, todate) {
    // let returnVal=true;
    let dFromdate = new Date(fromdate);
    let dTodate = new Date(todate);
    let difference = dTodate.getTime() - dFromdate.getTime();

    return difference / (24 * 3600 * 1000);
  }
}