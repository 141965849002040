import { Component, OnInit } from '@angular/core';

import { NavController,ModalController } from '@ionic/angular';
import { LoadingController,AlertController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-guest-house-assign',
  templateUrl: './guest-house-assign.page.html',
  styleUrls: ['./guest-house-assign.page.scss'],
})
export class GuestHouseAssignPage implements OnInit {
  GuestHouseList:any;
  HotelList:any;
  userid:any;
  DefaultGuestHouseId:any;
  constructor(private navParams:NavParams,public alertController: AlertController, public loadingController: LoadingController,
    public authService: AuthService,private modalCtrl:ModalController) { }

  ngOnInit() {
    this.GuestHouseList = JSON.parse(this.navParams.get('guesthouses'));
    //  //  console.log(JSON.stringify( this.GuestHouseList));
    this.userid = this.navParams.get('userid');
  }
  closeModal()
  {
    let guest_house_data = {status: false};
    this.modalCtrl.dismiss(guest_house_data);
  }

  onSelectGuestHouse(name,ghid,type){
    this.submitForwardReason(name,ghid,type);
   // let guest_house_data = {status: true,name: name, code: code};
   // this.modalCtrl.dismiss(guest_house_data);
  }
  async submitForwardReason(name,ghid,type) {
    const alert = await this.alertController.create({
      header: 'Confirm!',
      message: 'Want to assign this guest house ?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            //  //  console.log('Confirm Cancel');
          }
        }, {
          text: 'Yes',
          handler: (data) => {
            
              this.submitRequest(this.userid,ghid)
            
          }
        }
      ]
    });

    await alert.present();
  }
  async submitRequest(userid,GuestHouseId){
    const loading = await this.loadingController.create({
      animated:true,
      spinner:"bubbles",
      message: 'Updating ...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    await loading.present();
    const formObj = {
      username: userid,
      ghid: GuestHouseId

    };
    let serializedForm = JSON.stringify(formObj);
    await  this.authService.postDataWithAuth('/api/assignghincharge',serializedForm).subscribe(res => {       
        try{
          //  //  console.log( JSON.stringify(res));
            if(res['status']==1){
                this.presentAlert("Success","Request successfully updated !!!")
                 let guest_house_data = {status: true};
                  this.modalCtrl.dismiss(guest_house_data);
            }else{
              this.presentAlert('Error','Unable to update.');
            
            }
        
          }catch(Exce){
  
          }finally{          
            loading.dismiss();
         }
      }, (err) => {
        try{
         
          //  //  console.log( JSON.stringify(err));
             
        }catch(exception){
           //  //  console.log(exception);
        }finally{          
          loading.dismiss();
        }
      });
  }
  
  async  presentAlert(header,message) {
   
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: ['OK']
    });
    return await alert.present();
  }
  isValid(ghtype,ghid){
    let returnVal=true;
     
      return returnVal;
    
  }
  


}
