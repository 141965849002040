
export const environment = {
  production: false,
  url: '192.168.1.106:8082',

  login_url: '/api/auth/signin',
  registration_url: '/api/auth/signup',
  otp_verification_url: '/api/signin',
  resend_otp_url: '/api/signin',
  forgot_password_request_url: '/api/signin',
  reset_forgot_password_url: '/api/signin',
  resend_forgot_password_otp_url: '/api/signin',
  logout_url: '/api/signin',
  employee_profile_details_url: '/api/signin',
  update_firebase_token_url: '/api/signin',


  employee_booking_basic_url: '/api/signin',
  booking_request_url: '/api/signin',
  booking_cancel_url: '/api/signin',
  booking_history_url: '/api/signin',


  pending_request_for_approval_url: '/api/signin',
  booking_approve_url: '/api/signin',
  booking_reject_url: '/api/signin',
  approved_booking_history_url: '/api/signin',
  reject_booking_history__url: '/api/signin',


  incharge_basic_details_url: '/api/signin',
  guesthoust_daily_status_url: '/api/signin',
  room_availibility_single_day_url: '/api/signin',
  room_availibility_date_range_url: '/api/signin',
  confirm_booking_list_url: '/api/signin',
  waiting_list_booking_url: '/api/signin',
  room_availibility_by_request_url: '/api/signin',
  room_allocation_to_booking_url: '/api/signin',
  booking_forward_url: '/api/signin',
  room_search_for_block_url: '/api/signin',
  block_reserve_url: '/api/signin',
  view_currently_block_reserve_url: '/api/signin',
  release_block_room_url: '/api/signin',
  all_block_history_url: '/api/signin',


  caretaker_daily_status_url: '/api/signin',
  checkin_url: '/api/signin',
  checkout_url: '/api/signin',
  caretaker_checkin_pending_url: '/api/signin',
  caretaker_instruction_url: '/api/signin',
  checkin_history_url: '/api/signin'
  
};

