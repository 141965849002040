import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { LoadingController, NavController, ModalController, AlertController } from '@ionic/angular';

import { RestApiService } from '../../services/rest-api.service';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-employee-search',
  templateUrl: './employee-search.page.html',
  styleUrls: ['./employee-search.page.scss'],
})
export class EmployeeSearchPage implements OnInit {

  mySearchForm: FormGroup;
  searchtext: any;
  errMsg = '';

  employeeList: any;
  constructor(private modalCtrl: ModalController, public authService: AuthService, public modalController: ModalController, public router: Router,
    private route: ActivatedRoute, public api: RestApiService, public alertController: AlertController, public navController: NavController,
    public loadingController: LoadingController, private formBuilder: FormBuilder) {

    this.mySearchForm = this.formBuilder.group({
      searchtext: ['', Validators.compose([Validators.maxLength(20)])]
    });
  }

  ngOnInit() {
    this.employeeList = [];
  }



  
  doSearch() {
    let frmObj = this.mySearchForm.getRawValue();
    let searchtext = frmObj.searchtext;
    try {
      if (!isNaN(searchtext)) {
        if (searchtext.length ==6) {
          this.searchEmployee(frmObj.searchtext);
        }
      } else {
        if (searchtext.length > 3) {
          this.searchEmployee(frmObj.searchtext);
        }
      }
    } catch (r) { }


  }
  async searchEmployee(searchtext) {
    const loading = await this.loadingController.create({
      animated: true,
      spinner: "bubbles",
      message: 'Searching ...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    await loading.present();
    let param = {
      nameOrUsername: searchtext
    };

    let serilizedData = JSON.stringify(param);
    //  console.log( serilizedData);
    //'/api/instantbookingsearch',
    
    await this.authService.postDataWithAuth('/api/instantbookingsearch', serilizedData).subscribe(res => {
      try {


        console.log(JSON.stringify(res));
        this.employeeList = res;

      } catch (Exce) {

        // this.loadEmployeeDashBoard();
        // this.checkRule(); WhomForSelected
      } finally {

        loading.dismiss();
      }
    }, (err) => {
      try {
        this.errMsg = "** Something went wrong";
        //  console.log( JSON.stringify(err));

      } catch (exception) {
        //  console.log(exception);
      } finally {
        loading.dismiss();
      }
    });
  }

  selectEmployee(userid) {

    //alert("EWWEE "+userid); 100136
    let data = { status: true, userName: userid };
    this.modalCtrl.dismiss(data);
  }

}
