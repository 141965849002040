import { Component, OnInit } from '@angular/core';


import { ActivatedRoute, Router  } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { regexValidators } from '../../../validators/validator';
import { LoadingController,AlertController,NavController ,ModalController} from '@ionic/angular';
import { RestApiService } from '../../../services/rest-api.service';
import { NavParams } from '@ionic/angular';

import { AuthService } from '../../../services/auth.service';
@Component({
  selector: 'app-start-up-page',
  templateUrl: './start-up-page.page.html',
  styleUrls: ['./start-up-page.page.scss'],
})
export class StartUpPagePage implements OnInit {

  TotalBookingAavailable:any=0;
  TotalBookingConsume:any=0;
  TotalUpComing:any=0;
 
  
  Instructions:any="<ul><li>You may create Guest House Booking request for yourself, Dependent and Third Party.</li><li>Select Booking Type, Booked For, Guest House Location, Guest House Preferred, Check in and Check out date, then click 'Continue'. Based on your selection Guest Personal details will be displayed/have to be entered by you.</li><li>A request with more than 7 days will require approval from Functional Director. Actual allotment of rooms shall be done by Guest House In-Charge receipt of email approval.</li><li>Booking Request for Third party shall be generated with status 'Waiting List' and actual allotment of rooms will be done by Guest House In-Charge.</li><li> Email and/or SMS intimation to Requester will be sent by the system.</li></ul> ";


   constructor(private navParams:NavParams,public navCtrl:NavController,private modalCtrl:ModalController,public router: Router,public authService: AuthService, private route: ActivatedRoute,private formBuilder: FormBuilder,public api: RestApiService, public loadingController: LoadingController) { 
    try {
      // this.authService.getToken();
     } catch (error) {        
     }
   }
 
  ngOnInit() {
    try {
      this.TotalBookingAavailable =parseInt( this.navParams.get('available')); 
      if(this.TotalBookingAavailable<0){
        this.TotalBookingAavailable=0;
      }
    } catch (error) {
      
    }
    try {
      this.TotalBookingConsume = this.navParams.get('consumed'); 
      this.TotalUpComing = this.navParams.get('upcomming'); 
    } catch (error) {
      
    }
    this.loadEmployeeDashBoardCounters();
  }

  startBooking(){
  
    let result_data = {status: true,code:  1};
             
    this.modalCtrl.dismiss(result_data);
  }
  MyBookings(){    
    let result_data = {status: true,code:  2 };
    this.router.navigate(['booking-history']);      
   // this.modalCtrl.dismiss(result_data);  
  }
 
  async loadEmployeeDashBoardCounters(){
 
    await  this.authService.postDataWithAuthForDashbord('/api/empdashboardcounter',null).subscribe(res => {       
        try{
          //  console.log( JSON.stringify(res));
          this.TotalBookingAavailable=res["noofdaysavailable"];
          this.TotalBookingConsume=res["noofdaysconsumed"];
          this.TotalUpComing=res["noofdaysupcomingbooking"];
         
          //  console.log( JSON.stringify(res));
          }catch(Exce){
            
          }finally{ }
          try {
           
            if(this.TotalBookingAavailable<0){
              this.TotalBookingAavailable=0;
            }
          } catch (error) {
            
          }
      }, (err) => {
        try{
        
        }catch(exception){
           //  console.log(exception);
        }finally{  
        }
      });
  }

}
