import { Component, OnInit } from '@angular/core';

import { NavController,ModalController } from '@ionic/angular';
@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.page.html',
  styleUrls: ['./date-selector.page.scss'],
})
export class DateSelectorPage implements OnInit {

  isCheckInSelected:boolean=true;
  CheckInDateText:any;
  CheckInDate;

  isValidDate=true;


  constructor(private modalCtrl:ModalController) {

    this.CheckInDate= new Date(); 

    this.CheckInDateText=this.formatDate(this.CheckInDate);
   }


  ngOnInit() {
  }
  closeModal()
  {
    let guest_house_data = {status: false};
    this.modalCtrl.dismiss(guest_house_data);
  }
  selectCheckInDate(){
    this.isCheckInSelected=true;
  }
  
  selectCheckOutDate(){
    this.isCheckInSelected=false;
  }
  CheckInDateSelected(date){
    this.CheckInDate= new Date(date);
    this.CheckInDateText=this.formatDate(date);
  
  
   
  }


  formatDate(date) {
    let d = new Date(date),
      day = '' + d.getDate(),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }


  onSubmit(){
  
      let check_in_data = {status: true,selected_date: this.CheckInDate};
      this.modalCtrl.dismiss(check_in_data);
  


   
  }
}
