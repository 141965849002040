
import { AuthGuardService } from './services/auth-guard.service';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
 
  { 
    path: 'login',
    loadChildren: './pages/auth/login/login.module#LoginPageModule' 
  },
  { 
    path: 'employee-registration', 
    loadChildren: './pages/auth/employee-registration/employee-registration.module#EmployeeRegistrationPageModule' 
  },
  { 
    path: 'otp-confirmation', 
    loadChildren: './pages/auth/otp-confirmation/otp-confirmation.module#OtpConfirmationPageModule' 
  },
  { 
    path: 'forgot-password', 
    loadChildren: './pages/auth/forgot-password/forgot-password.module#ForgotPasswordPageModule' 
  },
  { 
    path: 'my-account', 
    loadChildren: './pages/auth/my-account/my-account.module#MyAccountPageModule' ,
    canActivate: [AuthGuardService]
  },
 

  { 
   path: 'employee-dash-board',
   loadChildren: './pages/employee/employee-dash-board/employee-dash-board.module#EmployeeDashBoardPageModule'
   },
  { 
    path: 'booking-details-form', 
    loadChildren: './pages/employee/booking-details-form/booking-details-form.module#BookingDetailsFormPageModule' ,
    canActivate: [AuthGuardService]
  },
  { path: 'booking-success', 
    loadChildren: './pages/employee/booking-success/booking-success.module#BookingSuccessPageModule',
    canActivate: [AuthGuardService]
  },
  { 
    path: 'booking-history', 
    loadChildren: './pages/employee/booking-history/booking-history.module#BookingHistoryPageModule',
    canActivate: [AuthGuardService] 
  },
  
  
  { 
    path: 'pending-requests', 
    loadChildren: './pages/approvals/pending-requests/pending-requests.module#PendingRequestsPageModule',
    canActivate: [AuthGuardService]  
  },
  { 
    path: 'approval-list', 
    loadChildren: './pages/approvals/approval-list/approval-list.module#ApprovalListPageModule',
    canActivate: [AuthGuardService]
  },
  { 
    path: 'rejection-list', 
    loadChildren: './pages/approvals/rejection-list/rejection-list.module#RejectionListPageModule',
    canActivate: [AuthGuardService]
  },
  


  { 
    path: 'care-taker-dash-board', 
    loadChildren: './pages/caretaker/care-taker-dash-board/care-taker-dash-board.module#CareTakerDashBoardPageModule',
    canActivate: [AuthGuardService]
  },
  { 
    path: 'guest-check-in', 
    loadChildren: './pages/caretaker/guest-check-in/guest-check-in.module#GuestCheckInPageModule' ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'guest-check-in-form', 
    loadChildren: './pages/caretaker/guest-check-in-form/guest-check-in-form.module#GuestCheckInFormPageModule',
    canActivate: [AuthGuardService]
  },
  { 
    path: 'guest-check-out', 
    loadChildren: './pages/caretaker/guest-check-out/guest-check-out.module#GuestCheckOutPageModule' ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'care-taker-instructions', 
    loadChildren: './pages/caretaker/care-taker-instructions/care-taker-instructions.module#CareTakerInstructionsPageModule' ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'care-taker-check-in-history', 
    loadChildren: './pages/caretaker/care-taker-check-in-history/care-taker-check-in-history.module#CareTakerCheckInHistoryPageModule' ,
    canActivate: [AuthGuardService]
  },

 
  { 
    path: 'in-charge-dashboard', 
    loadChildren: './pages/incharge/in-charge-dashboard/in-charge-dashboard.module#InChargeDashboardPageModule' ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'room-availibility', 
    loadChildren: './pages/incharge/room-availibility/room-availibility.module#RoomAvailibilityPageModule' ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'pending-bookings', 
    loadChildren: './pages/incharge/pending-bookings/pending-bookings.module#PendingBookingsPageModule' ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'room-allocation-history', 
    loadChildren: './pages/incharge/room-allocation-history/room-allocation-history.module#RoomAllocationHistoryPageModule' ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'gust-house-hotel-shifting-list', 
    loadChildren: './pages/incharge/gust-house-hotel-shifting-list/gust-house-hotel-shifting-list.module#GustHouseHotelShiftingListPageModule' ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'block-new-room', 
    loadChildren: './pages/incharge/block-new-room/block-new-room.module#BlockNewRoomPageModule' ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'currently-blocked-room-list', 
    loadChildren: './pages/incharge/currently-blocked-room-list/currently-blocked-room-list.module#CurrentlyBlockedRoomListPageModule' ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'blocked-room-history', 
    loadChildren: './pages/incharge/blocked-room-history/blocked-room-history.module#BlockedRoomHistoryPageModule' ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'available-rooms-for-block', 
    loadChildren: './pages/incharge/available-rooms-for-block/available-rooms-for-block.module#AvailableRoomsForBlockPageModule' ,
    canActivate: [AuthGuardService]
  },
 
 
 
  { 
    path: 'notifications', 
    loadChildren: './pages/common/notifications/notifications.module#NotificationsPageModule' ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'allocate-new-room', 
    loadChildren: './pages/incharge/allocate-new-room/allocate-new-room.module#AllocateNewRoomPageModule' ,
    canActivate: [AuthGuardService]
  },
 
 
  { 
    path: 'guest-house-selector', 
    loadChildren: './model/guest-house-selector/guest-house-selector.module#GuestHouseSelectorPageModule' ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'forwarded-hotel-list', 
    loadChildren: './model/forwarded-hotel-list/forwarded-hotel-list.module#ForwardedHotelListPageModule' ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'room-number-assign', 
    loadChildren: './pages/incharge/room-number-assign/room-number-assign.module#RoomNumberAssignPageModule' ,
    canActivate: [AuthGuardService]
  },
  {
     path: 'check-in-date-selector', 
     loadChildren: './model/check-in-date-selector/check-in-date-selector.module#CheckInDateSelectorPageModule' 
  },
  { 
    path: 'instant-booking', 
    loadChildren: './pages/incharge/instant-booking/instant-booking.module#InstantBookingPageModule'  ,
    canActivate: [AuthGuardService]
  },
  { 
    path: 'cancel-bookings', 
    loadChildren: './pages/incharge/cancel-bookings/cancel-bookings.module#CancelBookingsPageModule' ,
    canActivate: [AuthGuardService] 
  },
  
  { path: 'edit-booking-request', loadChildren: './pages/employee/edit-booking-request/edit-booking-request.module#EditBookingRequestPageModule' },
  { path: 'role-assignment', loadChildren: './pages/employee/role-assignment/role-assignment.module#RoleAssignmentPageModule' },
  { path: 'create-care-taker', loadChildren: './pages/employee/create-care-taker/create-care-taker.module#CreateCareTakerPageModule' },
  { path: 'user-deactivate', loadChildren: './pages/employee/user-deactivate/user-deactivate.module#UserDeactivatePageModule' },
  { path: 'system-variable-update', loadChildren: './pages/employee/system-variable-update/system-variable-update.module#SystemVariableUpdatePageModule' },
  { path: 'incharge-management', loadChildren: './pages/employee/incharge-management/incharge-management.module#InchargeManagementPageModule' },
  { path: 'quota-management', loadChildren: './pages/employee/quota-management/quota-management.module#QuotaManagementPageModule' },
  { path: 'guest-house-assign', loadChildren: './model/guest-house-assign/guest-house-assign.module#GuestHouseAssignPageModule' },
  { path: 'landing-page', loadChildren: './pages/common/landing-page/landing-page.module#LandingPagePageModule' },
  { path: 'view-third-party-users', loadChildren: './pages/employee/view-third-party-users/view-third-party-users.module#ViewThirdPartyUsersPageModule' },
  { path: 'incharge-reports', loadChildren: './pages/incharge/incharge-reports/incharge-reports.module#InchargeReportsPageModule' },
  { path: 'view-no-show-list', loadChildren: './pages/incharge/view-no-show-list/view-no-show-list.module#ViewNoShowListPageModule' },
  { path: 'date-range-selector', loadChildren: './model/date-range-selector/date-range-selector.module#DateRangeSelectorPageModule' },
  { path: 'date-selector', loadChildren: './model/date-selector/date-selector.module#DateSelectorPageModule' },
  { path: 'view-previous-bookings', loadChildren: './pages/approvals/view-previous-bookings/view-previous-bookings.module#ViewPreviousBookingsPageModule' },
  { path: 'check-room-availability', loadChildren: './pages/incharge/check-room-availability/check-room-availability.module#CheckRoomAvailabilityPageModule' },
  { path: 'booking-partial-cancellation', loadChildren: './pages/incharge/booking-partial-cancellation/booking-partial-cancellation.module#BookingPartialCancellationPageModule' },
  { path: 'get-device-info', loadChildren: './pages/common/get-device-info/get-device-info.module#GetDeviceInfoPageModule' },
  { path: 'booking-day-log', loadChildren: './model/booking-day-log/booking-day-log.module#BookingDayLogPageModule' },
  { path: 'test', loadChildren: './pages/common/test/test.module#TestPageModule' },
  { path: 'care-taker-device-assignment', loadChildren: './pages/employee/care-taker-device-assignment/care-taker-device-assignment.module#CareTakerDeviceAssignmentPageModule' },
  { path: 'new-device-add', loadChildren: './model/new-device-add/new-device-add.module#NewDeviceAddPageModule' },
  { path: 'care-taker-room-allocation', loadChildren: './model/care-taker-room-allocation/care-taker-room-allocation.module#CareTakerRoomAllocationPageModule' },
  { path: 'forward-booking', loadChildren: './pages/incharge/forward-booking/forward-booking.module#ForwardBookingPageModule' },
  { path: 'web-checkin-form', loadChildren: './pages/caretaker/web-checkin-form/web-checkin-form.module#WebCheckinFormPageModule' },
  { path: 'checkin-form-web', loadChildren: './pages/caretaker/checkin-form-web/checkin-form-web.module#CheckinFormWebPageModule' },
  { path: 'reallocation-after-checked-in', loadChildren: './model/reallocation-after-checked-in/reallocation-after-checked-in.module#ReallocationAfterCheckedInPageModule' },
  { path: 'reserve-room', loadChildren: './pages/incharge/reserve-room/reserve-room.module#ReserveRoomPageModule' },
  { path: 'employee-booking-partial-cancellation', loadChildren: './pages/employee/employee-booking-partial-cancellation/employee-booking-partial-cancellation.module#EmployeeBookingPartialCancellationPageModule' },
  { path: 'view-reserved-rooms', loadChildren: './pages/incharge/view-reserved-rooms/view-reserved-rooms.module#ViewReservedRoomsPageModule' },
  { path: 'landing-page', loadChildren: './pages/employee/landing-page/landing-page.module#LandingPagePageModule' },
  { path: 'start-up-page', loadChildren: './pages/employee/start-up-page/start-up-page.module#StartUpPagePageModule' },
  { path: 'select-blocked-rooms', loadChildren: './model/select-blocked-rooms/select-blocked-rooms.module#SelectBlockedRoomsPageModule' },
  { path: 'view-identity-image', loadChildren: './pages/common/view-identity-image/view-identity-image.module#ViewIdentityImagePageModule' },
  { path: 'guest-house-list', loadChildren: './pages/common/guest-house-list/guest-house-list.module#GuestHouseListPageModule' },
  { path: 'feedback', loadChildren: './model/feedback/feedback.module#FeedbackPageModule' },
  { path: 'home-screen', loadChildren: './pages/common/home-screen/home-screen.module#HomeScreenPageModule' },
  { path: 'approval-list-web', loadChildren: './pages/approvals/approval-list-web/approval-list-web.module#ApprovalListWebPageModule' },
  { path: 'view-previous-bookings-web', loadChildren: './pages/approvals/view-previous-bookings-web/view-previous-bookings-web.module#ViewPreviousBookingsWebPageModule' },
  { path: 'pending-requests-web', loadChildren: './pages/approvals/pending-requests-web/pending-requests-web.module#PendingRequestsWebPageModule' },
  { path: 'help', loadChildren: './pages/common/help/help.module#HelpPageModule' },
  { path: 'approve-booking-model', loadChildren: './model/approve-booking-model/approve-booking-model.module#ApproveBookingModelPageModule' },
  { path: 'date-range-inclusive', loadChildren: './model/date-range-inclusive/date-range-inclusive.module#DateRangeInclusivePageModule' },
  { path: 'employee-search', loadChildren: './model/employee-search/employee-search.module#EmployeeSearchPageModule' },
  { path: 'rejection-list-web', loadChildren: './pages/approvals/rejection-list-web/rejection-list-web.module#RejectionListWebPageModule' },
  { path: 'approver-input-from', loadChildren: './model/approver-input-from/approver-input-from.module#ApproverInputFromPageModule' },
  { path: 'return-to-guesthouse', loadChildren: './pages/incharge/return-to-guesthouse/return-to-guesthouse.module#ReturnToGuesthousePageModule' },
  { path: 'hotel-booking-partial-cancellation', loadChildren: './pages/incharge/hotel-booking-partial-cancellation/hotel-booking-partial-cancellation.module#HotelBookingPartialCancellationPageModule' },
  { path: 'view-monthly-report',
   loadChildren: './pages/incharge/view-monthly-report/view-monthly-report.module#ViewMonthlyReportPageModule',
   canActivate: [AuthGuardService]
  },
  { path: 'app-update', loadChildren: './model/app-update/app-update.module#AppUpdatePageModule' },
  { path: 'block-room-status-update',
   loadChildren: './pages/incharge/block-room-status-update/block-room-status-update.module#BlockRoomStatusUpdatePageModule' ,
   
   canActivate: [AuthGuardService]},
   
  { path: 'ex-employee-dashboard', loadChildren: './pages/exemployee/ex-employee-dashboard/ex-employee-dashboard.module#ExEmployeeDashboardPageModule' },
  { path: 'ex-employee-booking-form', loadChildren: './pages/exemployee/ex-employee-booking-form/ex-employee-booking-form.module#ExEmployeeBookingFormPageModule' },
  { path: 'ex-employee-booking-history', loadChildren: './pages/exemployee/ex-employee-booking-history/ex-employee-booking-history.module#ExEmployeeBookingHistoryPageModule' },
  { path: 'ex-employee-new-bookings', loadChildren: './pages/incharge/ex-employee-new-bookings/ex-employee-new-bookings.module#ExEmployeeNewBookingsPageModule' },
  { path: 'ex-employee-booking-register', loadChildren: './pages/incharge/ex-employee-booking-register/ex-employee-booking-register.module#ExEmployeeBookingRegisterPageModule' },
  { path: 'ex-employee-booking-shifting', loadChildren: './pages/incharge/ex-employee-booking-shifting/ex-employee-booking-shifting.module#ExEmployeeBookingShiftingPageModule' },
  { path: 'ex-employee-cancelled-bookings', loadChildren: './pages/incharge/ex-employee-cancelled-bookings/ex-employee-cancelled-bookings.module#ExEmployeeCancelledBookingsPageModule' },
  { path: 'periodic-report', loadChildren: './pages/incharge/periodic-report/periodic-report.module#PeriodicReportPageModule' },
  { path: 'vacancy-report', loadChildren: './pages/incharge/vacancy-report/vacancy-report.module#VacancyReportPageModule' },
  { path: 'vacancy-date-range-selector', loadChildren: './model/vacancy-date-range-selector/vacancy-date-range-selector.module#VacancyDateRangeSelectorPageModule' },
  { path: 'partial-room-unblocking', loadChildren: './pages/incharge/partial-room-unblocking/partial-room-unblocking.module#PartialRoomUnblockingPageModule' },
  { path: 'booking-tracker-model', loadChildren: './model/booking-tracker-model/booking-tracker-model.module#BookingTrackerModelPageModule' },
  { path: 'third-party-new-bookings', loadChildren: './pages/incharge/third-party-new-bookings/third-party-new-bookings.module#ThirdPartyNewBookingsPageModule' },
 ];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
