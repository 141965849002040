import { Component, OnInit } from '@angular/core';

import { NavController, ModalController } from '@ionic/angular';
import { LoadingController, AlertController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
@Component({
  selector: 'app-booking-tracker-model',
  templateUrl: './booking-tracker-model.page.html',
  styleUrls: ['./booking-tracker-model.page.scss'],
})
export class BookingTrackerModelPage implements OnInit {
  daysList: any;
  daysObj: any;
  bookingno: any;
  constructor(private navParams: NavParams, public alertController: AlertController, public loadingController: LoadingController,
    private modalCtrl: ModalController) {

  }

  ngOnInit() {
    this.daysList = this.navParams.get('daylist');

  }
  closeModal() {

    this.modalCtrl.dismiss();
  }

}
