import { Component, OnInit } from '@angular/core';
import { NavController,ModalController } from '@ionic/angular';
import { LoadingController,AlertController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';

import { AuthService } from '../../../services/auth.service';
@Component({
  selector: 'app-view-identity-image',
  templateUrl: './view-identity-image.page.html',
  styleUrls: ['./view-identity-image.page.scss'],
})
export class ViewIdentityImagePage implements OnInit {
  bookingallocationid:any;
  bookingno:any;
  base64Image:any;
  constructor(private navParams:NavParams,public alertController: AlertController, public loadingController: LoadingController,
    public authService: AuthService,private modalCtrl:ModalController) { }

  ngOnInit() {
    this.bookingno = this.navParams.get('bookingno');
    this.bookingallocationid = this.navParams.get('bookingallocationid');
  //  console.log("bookingno "+ this.bookingno);
  this.fetchPhoto(this.bookingno,this.bookingallocationid);
  }
  closeModal()
  {
  
    this.modalCtrl.dismiss();
  }
  async fetchPhoto(bookingno,bookingallocationid){
    const loading = await this.loadingController.create({
      animated:true,
      spinner:"bubbles",
      message: 'Loading ...',
      translucent: true,
      cssClass: 'custom-class custom-loading'
    });
    await loading.present();
    let params={
        bookingno:bookingno,
        bookingallocationid:bookingallocationid
    };
    let serilizedObj=JSON.stringify(params);
    //  console.log(serilizedObj);
    await  this.authService.getDataWithAuth('/api/getidproof/'+bookingallocationid).subscribe(res => {       
        try{
          let blob=new Blob([res],{ type:'image/jpeg'});
          //this.base64Image= res;
         // let imageObj=this.base64data;
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            this.base64Image = reader.result;
            //  console.log(this.base64Image);
          }
          
           //  console.log( res);
          }catch(Exce){
          
          }finally{          
            loading.dismiss();
         }
      }, (err) => {
        try{
          //  console.log("hi");
          //  console.log( JSON.stringify(err));
         
        }catch(exception){
           //  console.log(exception);
        }finally{         
          loading.dismiss();
        }
      });
  }
 
  async  presentAlert(header,message) {
    const alertController = document.querySelector('ion-alert-controller');
    await alertController.componentOnReady();
  
    const alert = await alertController.create({
      header: header,
      message: message,
      buttons: ['OK']
    });
    return await alert.present();
  }
 
  

}