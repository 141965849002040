import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SSLInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request and set the 'X-Skip-Certificate-Check' header to allow all certificates
    const modifiedRequest = request.clone({
      setHeaders: {
        'X-Skip-Certificate-Check': 'true',
      },
    });

    return next.handle(modifiedRequest);
  }
}